import axios from 'axios';
//查看基本信息
const getCompanyInfo = (data) => axios.get('/show/index/getCompanyInfo', data);
//合作伙伴
const partnerslist = (data) => axios.get('/show/index/getPartners', data);//合作伙伴
// 企业文化
const findAllNews = (data) => axios.get('/show/index/culture', data);//查询
const findInfo = (data) => axios.get('/sys/information/feedback/findInfo', data);//详情
//企业荣誉
const honorlist = (data) => axios.get('/show/index/getHonor', data);//查询
//产品
const productmold = (data) => axios.get('/show/index/mold/list', data);//类别
const productlist = (data) => axios.get('/show/index/getProduct', data);//查询
const productinfo = (data) => axios.get('/show/index/info/'+data.params.id );//详情
//用能统计
const energyCount = (data) => axios.get('/show/index/energyCount',data );//详情
//服务客户数量
const serviceCount = (data) => axios.get('/show/index/serviceCount',data );//详情
//增值服务
const addedServices = (data) => axios.get('/show/index/addedServices',data );//详情
//物联网表统计
const iotDev = (data) => axios.get('/show/index/iotDev',data );//详情
//节能减排状态
const energySavingStatus = (data) => axios.get('/show/index/energySavingStatus',data );//详情
//园区覆盖区域
const serviceCover = (data) => axios.get('/show/index/serviceCover',data );//详情


export default {
  getCompanyInfo,
  partnerslist,
  findAllNews,
  findInfo,
  honorlist,
  productmold,
  productlist,
  productinfo,
  energyCount,
  serviceCount,
  addedServices,
  iotDev,
  energySavingStatus,
  serviceCover,
}
