<template>
  <div class="mainbox">
    <div class="left">
      <div class="modulebox f_column">
        <div class="f_column">
          <div class="title"><span>公司简介</span></div>
          <div class="content aboutus" @click="$router.push('/companyProfile')">
            <div class="left"><img :src="basicinfo.pictures?basicinfo.pictures:require('../assets/images/noImage.png')"></div>
            <div class="right">
              <h1>{{basicinfo.companyName}}</h1>
              <div class="text">
                {{basicinfo.remark}}
              </div>
            </div>
          </div>
        </div>
        <div class="f_row">
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>企业荣誉</span></div>
            <div class="content honor">
              <p style="text-align: center;font-size: 1.2em;" v-if="honorData.length==0">暂无数据</p>

              <el-carousel v-if="!honorData.length==0" > 
                <el-carousel-item v-for="(e,i) in honorData" :key="i">
                  <img @click="clickhonor(item)" v-for="(item,i) in e" :key="i" :src="item.picture">
                </el-carousel-item>
              </el-carousel>

            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>企业文化</span></div>
            <div class="content culture"  @click.stop="findInfo"  >
              <p style="text-align: center;font-size: 1.2em;" v-if="cultureData.length==0">暂无数据</p>
              <vue-seamless-scroll :data="cultureData" :class-option="{limitMoveNum: 3,direction: 1,step: 0.4,}">
                <div  v-for="(e,i) in cultureData" :key="i" class="list">
                  <div class='covering' :data-id='e.id' ></div>
                  <div class="left"><img :src="e.titleUrl"></div>
                  <div class="right">
                    <h2>{{e.name}}</h2>
                    <div class="text">{{e.synopsis}}</div>
                  </div>
                </div>
              </vue-seamless-scroll>

            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>主要产品</span></div>
        <div class="content cplist">
          <p style="text-align: center;font-size: 1.2em;width: 100%;margin-top: 45%;" v-if="cplistData.length==0">暂无数据</p>

          <div class="list" @click="productinfo(item)" v-for="(item,index) in cplistData" :key="index">
            <div class="left"><img :src="item.pimg"></div>
            <div class="right">
              <h2>{{item.pname}}</h2>
              <div class="text">{{item.synopsis}}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="modulebox"  v-loading="energyloading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.5)"     >
        <div class="title"><span>用能统计</span></div>
        <div class="content energyStatistics"   >
          <div  class="type">
            <li @click="energyClick(e)" v-for="(e,i) in energyType" :key="i" :class="energyActive==e.id?'active':''">{{e.name}}</li>
          </div>
          <div class="top">
            <div ref="bar1" id="bar1" class="echarts"></div>
          </div>
          <div class="bottom">
            <div ref="bar2" id="bar2" class="echarts"></div>
            <div class="text"><li>电力 {{powerRatio}}%</li><li>水 {{waterRatio}}%</li><li>燃气 {{gasRatio}}%</li><li>燃煤 {{coalRatio}}%</li><li>燃油 {{oilRatio}}%</li></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="modulebox f_column">
        <div class="f_row">
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>服务覆盖区域</span></div>
            <div class="content region">
              <div class="li" v-for="(item,index) in regionlist" :key="index">
                <b>{{item.value}}</b>
                <span>{{item.text}}</span>
              </div>
            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>服务客户数量</span></div>
            <div class="content customer">
              <div class="numbers">
                <span>平台总注册用户数</span>
                <div class="box">
                  <label v-for="(e,i) in serviceCount.allArr " :key="i" >{{e}}</label>
                </div>
              </div>
              <div class="numbers">
                <span>本区域注册用户数</span>
                <div class="box">
                  <label v-for="(e,i) in serviceCount.areaAllArr " :key="i" >{{e}}</label>
                </div>
              </div>
              <div class="flexbox">
                <div class="list"><span>本年新增注册数</span><b>{{serviceCount.thisYear}}</b></div>
                <div class="list"><span>本月新增注册数</span><b>{{serviceCount.thisMonth}}</b></div>
                <div class="list"><span>今日新增注册数</span><b>{{serviceCount.today}}</b></div>
                <div class="list"><span>年增长数排名</span><b>{{serviceCount.today}}</b></div>
              </div>
            </div>
          </div>
        </div>
        <div class="f_row">
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>物联网表计统计</span></div>
            <div class="content iot">
              <div class="top"><span>总数量</span><b>{{iotDevCount.all}}</b></div>
              <div class="bottom">
                <div class="left"><span>电表</span><b>{{iotDevCount.power}}</b></div>
                <div class="right">
                  <div><span>水表</span><b>{{iotDevCount.water}}</b></div>
                  <div><span>气表</span><b>{{iotDevCount.gas}}</b></div>
                  <div><span>安全设备</span><b>{{iotDevCount.safe}}</b></div>
                  <div @mouseover="ismore=true"  @mouseout="ismore=false"   ><span>其他设备</span><b>{{iotDevCount.rests}}</b></div>
                </div>
              </div>
              <div class="morebox" v-show="ismore">
                <li><span>水压监测表</span><b>{{iotDevCount.hydraulic}}</b></li>
                <li><span>烟雾传感器</span><b>{{iotDevCount.smoke}}</b></li>
                <li><span>风向监测表</span><b>{{iotDevCount.wind}}</b></li>
                <li><span>光照监测表</span><b>{{iotDevCount.beam}}</b></li>
              </div>
            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>节能减排状态</span></div>
            <div class="content cdm">
              <div class="li" v-for="(item,index) in cdmlist" :key="index">
                <b>{{item.value}}</b>
                <label>{{item.dw}}</label>
                <span>{{item.text}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>增值服务</span></div>
        <div class="content increment">
          <div class="list" v-for="(item,index) in incrementlist" :key='index'>
            <div class="left"><img :src="item.src"></div>
            <div class="right">
              <p><b>{{item.value}}</b>{{item.dw}}</p>
              <p>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>合作伙伴</span></div>

        <div class="content partner">
          <p style="text-align: center;font-size: 1.2em;" v-if="partnerlist.length==0">暂无数据</p>
          <li v-for="(item,index) in partnerlist" :key='index'><img :src="item.url"></li>
        </div>
      </div>
    </div>


    <el-dialog title="产品" :visible.sync="dialogVisible" width="50%">
      <div class="dialogbox">
        <el-menu :default-active="activeName" class="cp-menu" mode="horizontal" >
          <el-menu-item  @click="getcplist(e.id,true)" 
          v-for="(e,i) in cplistclassify" :key="i" :index="e.id">{{e.name}}</el-menu-item>
        </el-menu>
        <div class="productbox">
          <div class="left">

            <li @click="productinfo(item)" 
              :class="productindex==item.id?'active':''" 
              v-for="(item,index) in moldcplistlist" :key="index">
              <img :src="item.pimg">{{item.pname}}
            </li>
            <p style="text-align: center;font-size: 1.2em;width: 100%;" v-if="moldcplistlist.length==0">暂无数据</p>
          </div>
          <div class="right">
            <h1>{{cpinfo.pname}}</h1>
            <div class="texthtml" v-html="cpinfo.content" ></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="cultureshow" width="50%">
      <div class="dialogbox qywh">
        <h1>{{cultureinfo.name}}</h1>
        <div class="texthtml" v-html="cultureinfo.content" ></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="cultureshow = false">关 闭</el-button>
      </span>
    </el-dialog>


    <el-dialog title="荣誉介绍" :visible.sync="honorshow" width="40%">
      <div class="honordialogbox ">
        <div class="img">
          <img :src="honorinfo.picture" :alt="honorinfo.name">
        </div>
        <div class="info">
          <h3>{{honorinfo.name}}</h3>
          <p>{{honorinfo.remark}}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="honorshow = false">关 闭</el-button>
      </span>
    </el-dialog>




  </div>
</template>

<script>
let echarts = require('echarts');
import vueSeamlessScroll from 'vue-seamless-scroll'
import api from '@/apis/index'
export default {
  components:{
    vueSeamlessScroll,
  },
  data() {
    return {
      energyCount:[],
      powerRatio:'',
      waterRatio:'',
      gasRatio:'',
      coalRatio :'',
      oilRatio:'',
      energyloading:true,
      energyActive:0,
      energyType:[
        {id:0,name:'电',key:'power', unit: 'KWH',registrum:1.229},
        {id:1,name:'水',key:'water', unit: 'T'  ,registrum:0.0857},
        {id:2,name:'气',key:'gas' ,unit: 'Nm³',registrum:13.3},
        {id:3,name:'煤',key:'coal' ,unit: 'L'  ,registrum:0.7143},
        {id:4,name:'油',key:'oil', unit: 'T'  ,registrum:1.4286},
      ],
      serviceCount:{
        allArr:[0],
        all: 0,//所有用户
        areaAllArr:[0],
        areaAll: 0,//区域下的用户
        order: 0,//排名
        thisMonth: 0,//本月新增
        thisYear: 0,//今年新增
        today: 0,//今日新增
      },
      iotDevCount:{
        all: 0,
        rests: 0,
        gas: 0,
        power: 0,
        safe: 0,
        water: 0,
        hydraulic: 0,
        beam: 0,
        smoke: 0,
        wind: 0,
      },
      honorshow:false,
      honorinfo:{
        name:"",
        picture: "",
        remark: "",
      },
      basicinfo:{},
      dialogVisible:false,
      activeName: '1458604655928209409',//类别

      cplistclassify:[
        {
          id: "1458604721703284737",
          name: "服务器"
        }
      ],
      productindex:0,
      moldcplistlist:[],
      cplistData:[
        // {id:0,pname:'创能云综合能效管理系统',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp1.png')},
        // {id:1,pname:'创能云直购电售电平台',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp2.png')},
        // {id:2,pname:'创能云电气火灾系统',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp3.png')},
        // {id:3,pname:'创能云节能服务平台',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp4.png')},
        // {id:4,pname:'创能云智能运维系统',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp5.png')},
        // {id:5,pname:'创能云电子商务平台',synopsis:'协助企业对水、电、气、煤、油等能源进行全方位的监测与管理',pimg:require('../assets/images/cp6.png')},
      ],
      cpinfo:{
        content:'',
        pname:'',
      },

      regionlist:[
        {id:0,text:'省',value:'0'},
        {id:1,text:'市',value:'0'},
        {id:2,text:'区',value:'0'},
        {id:3,text:'园区',value:'0'},
        {id:4,text:'行业',value:'0'},
        {id:5,text:'产业',value:'0'},
      ],
      cdmlist:[
        {id:0,text:'总能耗',value:'0',dw:'吨标煤'},
        {id:1,text:'总排放',value:'0',dw:'吨CO2'},
        {id:2,text:'同比',value:'0%',dw:''},
        {id:3,text:'碳资产托管企业数',value:'0',dw:'吨'},
        {id:4,text:'托管配额 CCER交易量',value:'0',dw:'吨'},
        {id:5,text:'托管配额 CCER交易额',value:'0',dw:'万元'},
      ],
      incrementlist:[
        {id:0,title:'直购电交易量',dw:'亿度',value:'0',src:require('../assets/images/t1.png')},
        {id:1,title:'运维设备数',dw:'台',value:'0',src:require('../assets/images/t3.png')},
        {id:2,title:'技术改造项目',dw:'个',value:'0',src:require('../assets/images/t2.png')},
        {id:3,title:'平台交易总额',dw:'万元',value:'0',src:require('../assets/images/t4.png')},
      ],
      partnerlist:[
        {id:0,url:require('../assets/images/noImage.png')},
        {id:1,url:require('../assets/images/noImage.png')},
        {id:2,url:require('../assets/images/noImage.png')},
        {id:3,url:require('../assets/images/noImage.png')},
        {id:4,url:require('../assets/images/noImage.png')},
        {id:5,url:require('../assets/images/noImage.png')},
        {id:6,url:require('../assets/images/noImage.png')},
        {id:7,url:require('../assets/images/noImage.png')},
        {id:8,url:require('../assets/images/noImage.png')},
        {id:9,url:require('../assets/images/noImage.png')},
      ],
      cultureinfo:{
        name:'',
        content:'',
      },
      cultureshow:false,
      cultureData:[
        // {
        //   name: "创能云智慧能源管理服务平台惊艳亮相2020年四川省节能环保品牌推广全川行（德阳站）活动",
        //   synopsis:'',
        //   titleUrl: "https://dycn-v2.oss-cn-chengdu.aliyuncs.com/dycn-v2/20210303/8edf431d44d54a4a907113579c6eb199.png"
        // }
      ],
      honorData:[
        // [
        //   {picture:require('../assets/images/noImage.png')},
        //   {picture:require('../assets/images/noImage.png')}
        // ]
      ],      
      bar1Chart:null,
      currentIndex:-1,


      ismore:false,
    };
  },

  methods:{
    bar1(lastYearArr=[0,0,0,0,0,0,0,0,0,0,0,0,0],thisYearArr=[0,0,0,0,0,0,0,0,0,0,0,0,0]) {
			

      this.bar1Chart = echarts.init(document.getElementById('bar1'))//this.$refs.bar1
      

			this.bar1Chart.setOption({
				tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:(params)=>{
            return `${params[0].name}<br/>${params[0].seriesName}: ${params[0].data}${this.energyType.find(e=>e.id==this.energyActive).unit}<br/>
           ${params[1].seriesName}: ${params[1].data}${this.energyType.find(e=>e.id==this.energyActive).unit}`
          }, //'{b}月<br/>{a0}: {c0}KWH<br/>{a1}: {c1}KWH',
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
          left: '4%',
          right: '2%',
          bottom: '4%',
          top:'16%',
          containLabel: true
        },
        legend: {
            left: 30,
            top:0,
            textStyle: { color: "#fff",fontSize: 20 },
            itemWidth: 15,
            itemHeight: 15,
            itemGap: 30
        },
        xAxis: {
          type: 'category',
          data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
          axisLine: {
            lineStyle: { color: '#3570ff' }
          },
          axisLabel: {  color:'#fff',fontSize: 20 }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show:true,
            lineStyle: {
              color: '#3570ff'
            }
          },
          splitLine: { show: false},
          axisLabel: { color:'#fff',fontSize: 20}
        },
        series: [{
          name: '去年',
          type: 'bar',
          barWidth: '15%',
          itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#fccb05'
                }, {
                    offset: 1,
                    color: '#f5804d'
                }]),
                borderRadius: [12,12,0,0],
            },
          },
          data: lastYearArr
        },
        {
          name: '今年',
          type: 'bar',
          barWidth: '15%',
          itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#8bd46e'
                }, {
                    offset: 1,
                    color: '#09bcb7'
                }]),
                borderRadius: [12,12,0,0],
            }
            
          },
          data: thisYearArr
        }]
			})
      
		},
    bar2(power=0,water=0,gas=0,coal=0,oil=0) {
			let bar2Chart = echarts.init(document.getElementById('bar2'))//(this.$refs.bar2) 
			bar2Chart.setOption({
        color:['#00ccff','#00ffcc','#eb6098','#b486f3','#f29149'],
				xAxis: {
          show:false,
        },
        yAxis: {
          type: 'category',show:false,
        },
        grid:{
          left:30,right:30,top:15,bottom:0
        },
        series: [
          {
            name: '电力', type: 'bar', stack: 'total', barWidth:30,
            data: [power]
          },
          {
            name: '水', type: 'bar', stack: 'total',
            data: [water]
          },
          {
            name: '燃气', type: 'bar', stack: 'total',
            data: [gas]
          },
          {
            name: '燃煤', type: 'bar', stack: 'total',
            data: [coal]
          },
          {
            name: '燃油', type: 'bar', stack: 'total',
            data: [oil]
          }
        ]
			})
      
		},

    productinfo(row){
      this.getcplist(row.moldId,false);
      this.activeName = row.moldId;
      this.productindex = row.id;

      api.productinfo({
        params:{
          id:row.id
        }
      }).then(res=>{
        if (res.code===10000) {
          this.cpinfo = res.data
          this.dialogVisible = true
          
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getcplist(moldId,isinfo){
      //产品列表
      api.productlist({
        params:{
          limit:100,
          moldId,
        }
      }).then(res=>{
        if (res.code===10000) {
          this.moldcplistlist = res.data.list
          //加载第一个
          if (isinfo) {
            if (this.moldcplistlist.length) {
              this.productinfo(this.moldcplistlist[0])
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 企业文化详情
    findInfo(e){
      if (e.target.getAttribute('data-id')) {
        api.findInfo({
          params:{
            id:e.target.getAttribute('data-id')
          }
        }).then(res=>{
          if (res.code===10000) {
            this.cultureinfo = res.data
            this.cultureshow = true

          } else {
            this.$message.error(res.message)
          }
        })
      }


    },
    //荣誉详情
    clickhonor(row){

      this.honorinfo = row
      this.honorshow = true
    },


   
    energyClick(row){

      this.energyActive = row.id

          
          //求和
          let lastYearArr = []
          let thisYearArr = []
          var lastYear =  this.energyCount.lastYear
          var thisYear =  this.energyCount.thisYear
          var power = 0
          var water = 0
          var gas = 0
          var coal = 0
          var oil = 0
          for (const iterator of thisYear) {
            power +=iterator.power
            water +=iterator.water
            gas +=iterator.gas
            coal +=iterator.coal
            oil +=iterator.oil
            let num = iterator[row.key]
            thisYearArr.push(num?parseInt(num):0)
          }
          power = parseInt(power * 1.229)
          water = parseInt(water * 0.0857)
          gas = parseInt(gas * 13.3)
          coal = parseInt(coal * 0.7143)
          oil = parseInt(oil * 1.4286)
          this.$nextTick(()=>{
            this.bar2(power,water,gas,coal,oil)
          })
          //算比例
          let sum = power+water+gas+coal+oil
          this.powerRatio = parseInt(power / sum *100 )
          this.waterRatio = parseInt(water / sum *100 )
          this.gasRatio = parseInt(gas / sum *100 )
          this.coalRatio = parseInt(coal / sum *100 )
          this.oilRatio = parseInt(oil / sum *100 )

          //柱状图

          for (const iterator of lastYear) {
            let num = iterator[row.key]
            lastYearArr.push(num?parseInt(num):0)
          }
          // console.log(lastYearArr,thisYearArr);
        this.$nextTick(()=>{
          this.bar1(lastYearArr,thisYearArr);
        })
















    }
  },

  mounted() {
    this.bar1();
    // this.bar2();
   

    
    setInterval( () =>{
      var dataLen = 12;
      // 取消之前高亮的图形
      this.bar1Chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
      this.currentIndex = (this.currentIndex + 1) % dataLen;
      //console.log(this.currentIndex);
      // 高亮当前图形
      this.bar1Chart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
      // 显示 tooltip
      this.bar1Chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
    }, 2000);


    //合作伙伴
    api.partnerslist().then(res=>{
      if (res.code===10000) {
        this.partnerlist = res.data.list
      } else {
        this.$message.error(res.message)
      }
    })
    //企业文化
    api.findAllNews({
      params:{
        limit:6,
        status:0
      }
    }).then(res=>{
      if (res.code===10000) {
        this.cultureData = res.data.list
      } else {
        this.$message.error(res.message)
      }
    })
    //企业荣誉
    api.honorlist().then(res=>{
      if (res.code===10000) {
        let baseArray = res.data.list //[1, 2, 3, 4, 5, 6, 7, 8];
        let len = baseArray.length
        let n = 2 //假设每行显示2个
        let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1)
        let arr = []
        for (let i = 0; i < lineNum; i++) {
          // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
          let temp = baseArray.slice(i * n, i * n + n)
          arr.push(temp)
        }
        this.honorData = arr

      } else {
        this.$message.error(res.message)
      }
    })   


    //产品类别
    api.productmold({
      params:{
        limit:10
      }
    }).then(res=>{
      if (res.code===10000) {
        this.cplistclassify = res.data.list
        if (this.cplistclassify.length) {
          this.activeName=this.cplistclassify[0].id
        }
      } else {
        this.$message.error(res.message)
      }
    })
    //产品
    api.productlist({
      params:{
        limit:6
      }
    }).then(res=>{
      if (res.code===10000) {
        this.cplistData = res.data.list
      } else {
        this.$message.error(res.message)
      }
    })
    //查看基本信息
    api.getCompanyInfo().then(res=>{
      if (res.code===10000) {
        this.basicinfo=res.data
      } else {
        this.$message.error(res.message)
      }
    })

    //用能统计
    api.energyCount().then(res=>{
      this.energyloading =false
      if (res.code===10000) {
        this.energyCount  =  res.data
        this.energyClick(this.energyType[0])
      } else {
        this.$message.error(res.message)
      }
    })


    //服务客户数量
    api.serviceCount().then(res=>{
      if (res.code===10000) {
        this.serviceCount = res.data
        this.serviceCount.allArr = this.serviceCount.all.toString().split('')
        this.serviceCount.areaAllArr = this.serviceCount.areaAll.toString().split('')
      } else {
        this.$message.error(res.message)
      }
    })


    //物联网表统计
    api.iotDev().then(res=>{
      if (res.code===10000) {
        this.iotDevCount = res.data
        this.iotDevCount.rests = 
        res.data.all -
        res.data.power - 
        res.data.water - 
        res.data.gas-
        res.data.safe-
        res.data.beam-
        res.data.hydraulic-
        res.data.smoke-
        res.data.wind
      } else {
        this.$message.error(res.message)
      }
    })
    //节能减排状态
    api.energySavingStatus().then(res=>{
      if (res.code===10000) {
        let data = res.data
        let tb = (data.bm - data.bmLast) / data.bmLast * 100
        tb = tb?parseInt(tb):0
        this.cdmlist=[
          {id:0,text:'总能耗',value:data.bm?parseInt(data.bm/10000):0,dw:'万吨标煤'},
          {id:1,text:'总排放',value: data.co2?parseInt(data.co2/10000):0,dw:'万吨CO2'},
          {id:2,text:'同比',value:tb+'%',dw:''},
          {id:3,text:'碳资产托管企业数',value:data.trusteeshipCompany,dw:'吨'},
          {id:4,text:'托管配额 CCER交易量',value:data.trusteeshipCCER,dw:'吨'},
          {id:5,text:'托管配额 CCER交易额',value:data.trusteeshipCCERPay,dw:'万元'},
        ]
      } else {
        this.$message.error(res.message)
      }
    })
    //增值服务
    api.addedServices().then(res=>{
      if (res.code===10000) {
        let data = res.data
        this.incrementlist=[
          {id:0,title:'直购电交易量',dw:'万度',value:data.direct?parseInt(data.direct/10000):0,src:require('../assets/images/t1.png')},
          {id:1,title:'运维设备数',dw:'台',value:data.devSize,src:require('../assets/images/t3.png')},
          {id:2,title:'技术改造项目',dw:'个',value:data.reform,src:require('../assets/images/t2.png')},
          {id:3,title:'平台交易总额',dw:'万元',value:data.sumPayment?parseInt(data.sumPayment/10000):0,src:require('../assets/images/t4.png')},
        ]
      } else {
        this.$message.error(res.message)
      }
    })
    //园区覆盖区域
    api.serviceCover().then(res=>{
      if (res.code===10000) {
        let data = res.data
        this.regionlist=[
        {id:0,text:'省',value:data.province},
        {id:1,text:'市',value:data.city},
        {id:2,text:'区',value:data.area},
        {id:3,text:'园区',value:data.park},
        {id:4,text:'行业',value:data.trade},
        {id:5,text:'产业',value:data.industry},
      ]


      } else {
        this.$message.error(res.message)
      }
    })

  },
  beforeDestroy(){
    //清除所有定时器
    let _end = setInterval(function(){},2000);
    for(let i =1;i<=_end;i++){
      clearInterval(i)
    }
  },  
}
</script>

<style lang="less" scoped>
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;
  &>.left{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: left;
    .modulebox{ flex: 1; flex-shrink: 0;}
    .modulebox:first-child{ margin-top: 0; flex: 2;}
    .f_row{ height: 50%; flex-shrink: 0;
      .f_column{ width: 50%; flex-shrink: 0;}
    }
  }
  &>.right{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: right;
    .modulebox{ flex: 1; flex-shrink: 0; overflow: hidden;}
    .modulebox:first-child{ margin-top: 0; flex: 2;}
    .f_row{ height: 50%; flex-shrink: 0; overflow: hidden;
      .f_column{ width: 50%; flex-shrink: 0;
        .modulebox{
          .content{ overflow: hidden;}
        }
      }
    }
  }
  .texthtml{
    margin-bottom: 5em;
  }
  .aboutus{ display: flex; z-index: 11; flex-direction: row; overflow: hidden; padding:.4rem 1rem 1.5rem;
    
    .left{ width: 43%; height: 100%; flex-shrink: 0; overflow: hidden; margin-right: 1rem;
      img{ width: 100%; height: 100%;
        max-height: 380px;
      }
    }
    .right{ flex: 1; height: 100%; overflow: hidden;
      h1{ font-size: 1.3rem; color: #fff; text-align: center; 
      margin: 5px 0 15px; font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      
      }
      .text{ text-indent: 2rem; padding:.5rem; line-height:1.6rem;
        height: 13em;
        overflow: auto;
      }
    }
  }
  .honor{
    .el-carousel__item{ padding:.4rem; display: flex; flex-direction: row; justify-content: space-evenly;
    }

    /deep/ .el-carousel{ height: 100%; overflow: hidden;
      .el-carousel__container{ height: 83%;}
      .el-carousel__indicators--horizontal{ bottom: 30px;}
      .el-carousel__button{ height: 10px; width: 45px;}
      .el-carousel__indicator.is-active button{ width: 65px; background: #ffff00;}
      img{
        width: 270px;
      }
    }

  }
  .honordialogbox{height: 40rem;display: flex;
    .img{ width: 30rem; height: 40rem; margin-right: 1.2rem;
      img{ width:100%; height: 100%;}
    }
    .info{ 
      h3{margin-bottom: .6rem;}
    }
  }
  .culture{ padding: .2rem 0 1.2rem; height: 16rem; overflow:hidden; flex: none;
    .list{ display: flex; flex-direction: row; overflow: hidden; padding:.4rem 1rem; height: 8rem;
      position: relative;
      .covering{
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%; 
        z-index: 200;
      }
      .left{ width: 8rem; overflow: hidden; margin-right:1rem; flex-shrink: 0;
        img{ width: 100%; height: 7rem;}
      }
      .right{ flex: 1; overflow: hidden;
        h2{ font-size: 1rem; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; margin-bottom: .4rem; color: #fff;}
        .text{ 
          font-size: .9rem; 
          overflow: hidden; 

        }
      }
    }
  }
  .cplist{ display: flex; flex-direction: row; flex-wrap: wrap; padding:.4rem;
    .list{ 
      cursor: pointer;
      width: 50%; padding:.4rem 1rem; display: flex; flex-direction: row; flex-shrink: 0; overflow: hidden;
      .left{ width: 4.2rem; height:4.2rem; flex-shrink: 0; margin-right: 1rem;
        img{ width: 100%; height: 100%;}
      }
      .right{ flex: 1; overflow: hidden;
        h2{ font-size: 1rem; white-space:nowrap; overflow:hidden; text-overflow:ellipsis; margin-bottom: .4rem; color: #fff;}
        .text{ font-size: .9rem; overflow: hidden;
          height: 1.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .energyStatistics{ display: flex; flex-direction: column; position: relative;
    .type{ position: absolute; z-index: 200; top: -.7rem; right: 1rem; border: #005ae4 solid 1px;
      li{ display: inline-block; padding:8px 25px 10px; position: relative; cursor: pointer;}
      li.active,li:hover{ background: #3366ff; color: #fff;}
      li.active::after{ content: ' '; position: absolute; bottom: 0; left: 50%; margin-left: -10px; width: 0; height: 0; border-style: solid; border-width: 10px; border-color:transparent transparent #00ffcc transparent;}
    }
    .top{ flex: 1; z-index: 99;}
    .bottom{ height: 5rem; flex-shrink: 0; display: flex; flex-direction: column; overflow: hidden;
      .text{ display: flex; flex-direction: row; align-items: center; justify-content: space-around; padding:.4rem .8rem 1rem;}
    }
  }
  .region{ display: flex; flex-direction: row; flex-wrap: wrap; padding:.4rem .2rem; justify-content: space-evenly;
    .li{ width: 29%; height: 45%; display: flex; flex-direction: column; align-items: center; justify-content: center; border-radius: .5rem; background: rgba(255, 255, 255, .1);
      b{ font-size: 1.9rem; font-weight: 500; margin-bottom: 4px;}
    }
    .li:nth-child(1) b{ color: #00ffcc;}
    .li:nth-child(2) b{ color: #ffff00;}
    .li:nth-child(3) b{ color: #00ff00;}
    .li:nth-child(4) b{ color: #eb6098;}
    .li:nth-child(5) b{ color: #00ccff;}
    .li:nth-child(6) b{ color: #b486f3;}
  }
  .customer{ display: flex; flex-direction: column;
    .numbers{ margin-bottom: .6rem;
      span{ color: #fff;}
      .box{ margin-top: .2rem;
        label{ display: inline-block; padding:0 4px; margin-right: 8px; border: #005be3 solid 1px; background: rgba(0,0,0,.2); line-height: 2.2rem; box-shadow: 0 0 6px #00ccff; font-size: 2rem; font-weight: 600; color: #ffff00;}
      }
    }
    .flexbox{ flex: 1; padding-bottom: .6rem; overflow: hidden; display: flex; flex-direction: row; flex-wrap: wrap;
      .list{ width: 50%; display: flex; flex-direction: row; align-items: center;
        span{ display: block; line-height: 1.2rem; width: 100px; text-align: right; color: #fff; margin-right: .8rem;}
        b{ font-size: 1.8rem; font-weight: 500; color: #00ff00;}
      }
    }
  }
  .iot{ display: flex; flex-direction: column; padding:.4rem 1rem 1rem; position:relative;
    b{ font-size: 1.8rem; font-weight: 500; color: #ffff00; text-shadow: 1px 1px 3px #111;}
    span{ color: #fff;}
    .top{ height: 4.8rem; display: flex; flex-direction: row; margin-bottom: 1rem; background: #006580; align-items: center; padding:0px 1rem;
      b{ margin-left: 10px;}
    }
    .bottom{ display: flex; flex-direction: row; flex: 1;
      .left{ flex: 1; display: flex; flex-direction: column; margin-right: 1rem; background: #007f80; justify-content: center; align-items: center;
        b{ margin-top: 10px;}
      }
      .right{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;
        div{ width: 46.4%; display: flex; flex-direction: column; justify-content: center; align-items: center;
          b{ margin-top: 0px;}
        }
        div:nth-child(1){ background: #7f8000; margin-bottom: 1rem;}
        div:nth-child(2){ background: #5a437a; margin-bottom: 1rem;}
        div:nth-child(3){ background: #80334d;}
        div:nth-child(4){ background: #008000; cursor: pointer;}
      }
    }
    .morebox{ position:absolute; width: 94%; z-index: 199; height: 16rem; background:rgba(2, 11, 92, 1); border: #3366ff solid 1px; bottom:1rem; left:28rem; display: flex; flex-direction: column; align-items: center;
      li{ display: flex; flex-direction: row; flex: 1; width: 100%; align-items: center; border-bottom: #3366ff dashed 1px;
        span{ width: 50%; text-align: right;}
        b{ flex: 1; text-align: center;}
      }
      li:last-child{ border-bottom: none;}
    }
  }
  .cdm{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content:center; padding: .4rem 1rem 1rem;
    .li{ width: 33.33%; height: 50%; display: flex; flex-direction: column; align-items: center; justify-content: center; background: rgba(8, 23, 149, .5); border: #3570ff dashed 1px;
      b{ font-size: 1.9rem; font-weight: 500; margin-bottom: 4px; color: #fff;}
      label{ font-size: .8rem; opacity: .8;}
      span{ padding:0 1.4rem;text-align: center;}
    }
    .li:nth-child(1),.li:nth-child(2),.li:nth-child(3){ border-bottom: 0;}
    .li:nth-child(2),.li:nth-child(5){ border-left: 0; border-right: 0;}
  }
  .increment{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; padding-top: .4rem;
    .list{ width: 47%; height: 43%; display: flex; flex-direction: row; align-items: center; background: rgba(8, 23, 149, .5); border: #3570ff dashed 1px;
      .left{ width: 5rem; height: 5rem; margin-right: 1rem; flex-shrink: 0; margin-left: 1.5rem;
        img{ width: 100%; height: 100%;}
      }
      .right{ font-size: 1.1rem; display: flex; flex-direction: column; justify-content: center;
        b{ font-size: 2.1rem; font-weight: 500; margin-right: 10px;}
      }
    }
    .list:nth-child(1) b{ color: #00ccff;}
    .list:nth-child(2) b{ color: #b486f3;}
    .list:nth-child(3) b{ color: #00ffcc;}
    .list:nth-child(4) b{ color: #ffff00;}
  }
  .partner{ display: flex; flex-direction: row; 
    flex-wrap: wrap; 
    // justify-content: space-evenly; 
    
    padding: 0 .2rem .8rem 1rem;
    li{ width: 17.5%; height: 43%;margin: .6rem;
      img{ width: 100%; height: 100%;}
    }
  }
  .dialogbox{ width: 100%; height: 100%; height: 60rem; overflow: hidden;}
  .cp-menu{
 
    background:#e3000000;
    .el-menu-item{
      color: #ccd0f2;
      font-size: 1.2rem;
      padding: 0.4rem 2rem 1rem;
    }
    .is-active{
      color: #00ccff;
    }
    .el-menu-item:not(.is-disabled):focus,  .el-menu-item:not(.is-disabled):hover{
      background:#e3000000;
      color: #00ccff;

    }
    
    .el-menu.el-menu--horizontal{
      border-bottom: solid 3px #005ae4;
    }
    
  }
  .productbox{ margin-top: 20px; display: flex; flex-direction: row; height: 100%; overflow: hidden;
    .left{ width: 22rem; height: 100%; overflow-y: auto; border: #005ae4 solid 2px; margin-right: 1rem;
      li{ display: flex; flex-direction: row; align-items: center; cursor: pointer; background: #1d2088; margin:1.5rem; font-size: 1rem;
        img{ margin-right: 1rem; width: 3.5rem; height: 3.5rem;}
      }
      li.active{ background: #005be3; color: #fff;}
      li:hover{ color: #fff;}
    }
    .right{ width: 100%; height: 100%; flex: 1; overflow-y: auto;
      h1{ border: #00ccff dashed 2px; color: #00ccff; font-size: 1.1rem; font-weight: 500; text-align: center; padding:.6rem; margin-bottom: 1rem;}
    }
  }
  .qywh{
    overflow: auto;
      h1{ border: #00ccff dashed 2px; color: #00ccff; font-size: 1.1rem; font-weight: 500; text-align: center; padding:.6rem; margin-bottom: 1rem;}

  }



}
</style>
